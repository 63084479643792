/*WOOK*/

@font-face {
  font-family:'mainFont';
  src: url(../font/roboto-condensed-v17-latin-regular.woff);
}



body{padding: 0; margin: 0; font-family:'mainFont'; background-color: #fff;}
a, button:focus{outline: inherit; text-decoration: none;}
.trngs{transition:all 300ms ease-in;}
.wtBG{background-color:#fff;}
.grBG{background-color:#f9f9f9;}

h1, h2, h3, h4 { font-smooth: always; line-height: 1.2em; margin: 0; padding: 0; left: 2px; font-weight: 600;}
h1{ font-size:33px;}
h2{ font-size:22px;}
h3{ font-size:22px; }

p{ margin: 0; font: 16px; padding: 0; line-height: 1.2em; text-align:  justify;}

.fs-13{font-size: 13px;}
.fs-16{font-size: 16px;}
.fs-18{font-size: 18px;}

form input{ font-family:'mainFont'; }
form input:focus{outline: inherit;}

.cn-width{ width: 100%; max-width: 1170px; margin-left: auto; margin-right: auto; }
.mRow{ font-size: 0;}
.cl-2, .cl-3, .cl-4, .cl-1-3, .brand, .nav{ display: inline-block; vertical-align: top;}
.cl-2{width: 50%;}
.cl-3{width: 33.333%;}
.cl-4{width: 25%;}
.cl-1-3{width: 66.666%;}
.pd-tb-15-20{ padding: 15px 0px;}
.pd-tb-20{ padding: 20px 0px;}

.pd-lrtb-15{ padding: 15px 20px;}

.mr-15{margin:15px;}
.mr-10{margin: 10px;}
.mr-r-20{margin-right: 10px;}

.txC{text-align: center;}
.txL{text-align: left;}
.txR{text-align:right;}
.pd-15{ padding: 15px;}
.pd-20{ padding: 20px;}


/* header */
.nav-bar{padding: 35px 0px;}
.nav-bar .brand{ width: 33.333%;}
.nav-bar .brand img{ height:32px; width: auto;}
.nav-bar .nav{width: 66.666%;}
.nav-bar .nav a, .foot .nav a{ display: inline-block; margin:0px 15px; font-weight: 600; color:#262626; font-size: 18px; line-height:32px; letter-spacing: 1px;}
.nav-bar .nav a:hover, .foot .nav a:hover{color: #080808;}

/* Top Section */
.fePost{ display: block; position: relative; overflow: hidden; border-radius: 4px;}
.fePost img{width: 100%; height: auto;}
.fePostC{position: absolute; height:100%; width: 100%; top: 0; left: 0; display: flex; align-items: flex-end; background: rgba(0,0,0,0.5);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4962359943977591) 0%, rgba(0,0,0,0) 100%);}
.fePostC div{ color: #fff;}
.fePostC div .span{position: absolute; top: 25px; display: block; background-color:#1a1a1a; padding: 8px 25px 8px 15px; left: 0; letter-spacing:3px; border-radius: 0px 20px 20px 0px;}
.fePostC div .span:before{ content: '\2605'}
.fePostC div h1{letter-spacing: 1px;}
.fePostC div p{margin-top: 10px; letter-spacing: 1px;}
.fePostC div p i{font-style: normal; float: right; letter-spacing: 2px;}


/* con-sec Section */

.conSec{ margin-top: 15px; background-color:#f9f9f9;}
.postT{ background-color: #fff; border-radius: 4px; overflow:hidden; }
.postT h2{color: #282828;}
.postT p{margin-top: 10px; letter-spacing: 0px; color:#3f3f3f; }
.postT p i{font-style: normal; float: right; letter-spacing: 1px;}
.postT div a{ display: block; text-align: left; font-size: 15px; color:#262626; margin-top: 10px; margin-bottom: 5px; }
.postT div a:hover{ color: #000;}

.sidHEADing{ display:inline-block; padding: 10px 25px; background-color: #2d2d2d; color:#fff; margin: 30px 0px 10px 0px; border-radius: 0px 20px 20px 0px;}
.sidePost{ padding:15px; margin: 10px 20px; color: #282828; border-radius: 4px; border: 1px solid #f4f4f4; display: block;}
.sidePost p{ color: #717171; margin-top: 10px;}
.sidePost:hover{background-color: #f4f4f4;}

/* footer */

footer{padding: 45px 0px; color: #282828;}
.newsLetter h1{ margin-bottom: 20px; }
.newsLetter form input[type=text]{background-color:#fff; display:block; margin-left: auto; margin-right: auto; padding: 20px; width:calc(100% - 40px); max-width: 500px; text-align: center; color: #454545; font-size: 18px; border:1px solid #f6f6f6; border-radius: 4px;  letter-spacing: 1px;  font-weight: 600; }
.newsLetter form input[type=text]::placeholder {  color:#d0d0d0;  opacity: 1;}
.newsLetter form input[type=text]:-ms-input-placeholder { color:#d0d0d0; }
.newsLetter form input[type=text]::-ms-input-placeholder { color:#d0d0d0;}

.newsLetter form input[type=submit]{ border: 0; margin-top:20px; padding: 14px 40px; font-size:22px; font-weight: 600; letter-spacing: 2px; background-color: #e5e5e5; border-radius: 4px; color: #282828;  }
.newsLetter form input[type=submit]:hover{background-color:#282828; color: #e5e5e5; }
.foot{margin-top: 15px;}
.foot img{ width: 110px; height: auto;}
.foot .nav{display: block;}
.foot p{ margin-top: 10px; color: #282828;}
.blog-content{
  padding:20px;
}
.blog-content p{
  font-size: 16px !important;
  line-height: 18px;
  margin-bottom: 10px;
}